<template>
  <div>
    <nav-bar headerTitle="会员案例" ref="head" :show-back="true" :header-back="newAppBack" />
    <div v-if="params.venueId" class="filter" :class="{filtermini: appTypeStr === 'mini'}">
      <div class="filter-box flex flex-wrap">
        <div @click="filterChange(item)" :class="['filter-item', 'f24', 'row-center-center', curFilter.title === item.title ? 'active' : '']" v-for="item in filters" :key="item.title">{{ item.title }}</div>
      </div>
      <div v-if="subFilters.length" @click="filterShow = true" class="filter-sub f26 row-start-center">
        {{ params.projectName || '筛选' }}
        <span class="angle"></span>
      </div>
    </div>
    <div class="container col-start-center">
      <van-list v-model="loading" :finished="finished" @load="getList">
        <div class="case-box">
          <img @click="preview(item, index)" v-for="item,index in records" :key="item.casePhotoId" :src="item.casePhoto" alt="" class="case">
        </div>
      </van-list>

      <div class="empty col-start-center" v-if="records.length === 0">
        <img src="https://img.chaolu.com.cn/ACT/mini-records/empty.png" alt="">
        <p class="f26">暂无案例</p>
      </div>
    </div>

    <van-popup v-model="filterShow" round position="bottom">
      <van-picker @confirm="filterConfirm" @cancel="filterShow = false" title="请选择" show-toolbar :columns="subFilters" />
    </van-popup>

    <van-image-preview v-model="previewShow" :images="previews" :start-position="previewIndex" @change="onPreviewChange">

    </van-image-preview>



  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

import wx from 'weixin-js-sdk'


export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      filters: [],
      curFilter: {},

      records: [], // 训记列表

      params: {},

      filterShow: false,
      previewShow: false,
      previewIndex: 0,
      loading: true,
      finished: false,

    }
  },
  computed: {
    subFilters() {
      let columns = []
      if (this.curFilter.item) {
        this.curFilter.item.itemList.map((item, index) => {
          if (item.child) {
            columns.push({ text: item.title, children: [] })
            item.child.itemList.map((child) => {
              columns[index].children.push({ text: child.title })
            })
          } else {
            columns.push(item.title)
          }
        })
      }
      return columns
    },

    previews() {
      return this.records.map((item) => item.casePhoto)
    }
  },
  async created() {
    // 初始化
    await this.$getAllInfo();
    initBack()

    this.params.venueId = getParam().venueId
    this.params.teacherId = getParam().teacherId

    if (getParam().venueId) {
      this.params = {
        page: 1,
        projectName: '',
        templateName: '',
        size: 10,
        venueId: getParam().venueId
      }
    }

    if (getParam().teacherId) {
      this.params = {
        teacherId: getParam().teacherId,
        page: 1,
        rows: 10,
      }
    }

    this.loading = false
    this.getFilter()
  },

  methods: {
    newAppBack,
    getList() {
      if (getParam().teacherId) this.getTeacherList()
      if (getParam().venueId) this.getVenueList()
    },
    getTeacherList() {
      this.$axios.post(this.baseURLApp + '/teacherusercase/case/page', this.params).then((res) => {
        if (!res.data) {
          this.finished = true
          return
        }
        this.records = this.records.concat(res.data.records)
        if (res.data.records.length < this.params.rows) {
          this.finished = true
        }
        this.params.page++
        setTimeout(() => {
          this.loading = false
        }, 100);
      })
    },
    getVenueList() {
      this.$axios.post(this.baseURLApp + '/teacherusercase/pageByVenue', this.params).then((res) => {
        if (!res.data) {
          this.finished = true
          return
        }
        this.records = this.records.concat(res.data)
        if (res.data.length < this.params.size) {
          this.finished = true
        }
        this.params.page++
        setTimeout(() => {
          this.loading = false
        }, 100);
      })
    },
    getFilter() {
      this.$axios.post(this.baseURLApp + '/case/config/getCommonConfig', {}).then((res) => {
        this.filters = res.data
        this.curFilter = res.data[0] || {}
        this.params.templateName = this.curFilter.title
      })
    },
    filterChange(item) {
      this.curFilter = item
      this.params.templateName = this.curFilter.title
      this.params.projectName = ''
      this.reset()
    },

    filterConfirm(v) {
      this.params.projectName = Array.isArray(v) ? v.join('') : v
      this.filterShow = false
      this.reset()
    },
    reset() {
      this.params.page = 1
      this.finished = false
      this.loading = true
      this.records = []
      this.getList()
      window.scrollTo(0, 0)
    },
    preview(item, index) {
      this.previewShow = true
      this.previewIndex = index
    },
    onPreviewChange(index) {
      this.previewIndex = index
      if (index === this.records.length - 3) {
        this.getList()
      }
    }
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.filter {
  padding: 24px 32px;
  position: sticky;
  top: calc(99px + env(safe-area-inset-top));
  background-color: #fff;
}
.filtermini{
    top: 0;
}

.filter-box {
  .filter-item {
    padding: 0 17px;
    height: 52px;
    background: #F5F5F5;
    border-radius: 4px;
    margin: 0 12px 0 0;

    &.active {

      background: #242831;
      color: #fff;
    }
  }
}

.filter-sub {
  margin: 32px 0 0;

  .angle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 6px 0 6px;
    border-color: #242831 transparent transparent transparent;
    margin: 0 0 0 12px;
  }
}

.container {
  background-color: #F7F7F7;
  box-sizing: border-box;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  width: 100%;
  padding: 25px 24px calc(24px + env(safe-area-inset-bottom));

  .case-box {
    width: 702px;
    display: flex;
    flex-wrap: wrap;
  }

  .case {
    width: 345px;
    margin: 0 0 12px;
    object-fit: cover;
    border-radius: 4px;
  }
    .case:nth-child(2n){
        margin-left: 12px;
    }

  .empty {
    margin: 200px auto;

    img {
      width: 320px;
      margin: 0 0 12px;
    }

    p {
      color: #6C727A;
    }
  }
}
/deep/.van-image-preview__overlay {
  background-color: #000;
}
</style>
