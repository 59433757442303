import { render, staticRenderFns } from "./user-case.vue?vue&type=template&id=54934852&scoped=true&"
import script from "./user-case.vue?vue&type=script&lang=js&"
export * from "./user-case.vue?vue&type=script&lang=js&"
import style0 from "./user-case.vue?vue&type=style&index=0&id=54934852&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54934852",
  null
  
)

export default component.exports